<template>
  <div>
    <v-data-table
      :fixed-header="false"
      :height="undefined"
      v-bind="dataTableAttrs"
      v-if="assessmentId"
      :headers="headers"
      :items="list"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page.sync="itemsPerPage"
      v-model="selectedItems"
      :show-select="false"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:top="{ headers }">
        <rs-table-title-bar
          v-if="$refs.headSearch"
          class="mt-6"
          title=""
          icon=""
          @clear-filters="$refs.headSearch.reset"
          @reload="loadList"
          :search.sync="search.query"
          :edit-enabled="false"
        >
          <template slot="buttons:append">
            <!--  -->
          </template>
        </rs-table-title-bar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.prepend="{ headers }">
        <rs-table-head-search
          :headers="headers"
          :search.sync="search"
          ref="headSearch"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.append="{ headers }">
        <rs-table-foot-totals :headers="headers" :totals="footTotals" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.amount="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";

export default {
  name: "HouseAssessmentList",
  mixins: [hasDataTable],
  props: {
    assessmentId: {
      type: Number,
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["blocks", "cluster", "clusterId"]),
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    assessmentId() {
      this.loadList();
    },
    clusterId() {
      this.loadList();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.assessmentId) {
        this.loadList();
      }
    });
  },
  data() {
    return {
      headers: [],
      search: {
        debt_status: null,
      },
      options: {
        sortBy: ["door_number"],
      },
    };
  },
  methods: {
    setHeaders() {
      const headers = [
        {
          text: "Blok",
          value: "block",
          searchable: "select",
          options: () => this.blocks,
        },
        {
          text: "BB",
          value: "door_number",
          searchable: "text",
        },
        {
          text: "BB Tipi",
          value: "house_type",
          searchable: "select",
          options: () => this.cluster.house_types,
        },
        {
          text: "Tahakkuk Eden",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ev Sahibi",
          value: "owners",
          searchable: "text",
        },
        {
          text: "Kiracı",
          value: "renter",
          searchable: "text",
        },
      ];

      this.headers = headers.filter((item) => {
        if (item.show === undefined) {
          return true;
        }

        return item.show;
      });
    },
    loadList() {
      if (this.isLoading || !this.assessmentId) {
        return;
      }

      this.setHeaders();

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options),
        {
          assessment_id: this.assessmentId,
          cluster_id: this.clusterId,
        }
      );

      this.$api
        .query("house-assessments", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;

            this.$emit("update:totals", response.data.meta.totals);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
