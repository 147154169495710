var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.cluster)?_c('v-data-table',_vm._b({attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(!_vm.hideTitleBar && _vm.$refs.headSearch)?_c('rs-table-title-bar',{attrs:{"title":"Bağımsız Bölümler","icon":"mdi mdi-home","add-route":{
          name: 'definitions.houses.create',
          params: _vm.blockId ? { formParams: { block_id: _vm.blockId } } : {},
        },"edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query,"show-delete":"","delete-enabled":_vm.deleteEnabled,"delete-tooltip":"Sadece hiç borçlandırma yapılmamış bağımsız bölümleri silebilirsiniz."},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"click:edit":_vm.handleEditClick,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)},"click:delete":_vm.handleDeleteClick}}):_vm._e()]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
          name: 'definitions.houses.edit',
          params: { id: item.id },
        }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.door_number",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{
          name: 'definitions.houses.show',
          params: { id: item.id },
        }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.renter",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getRenterNames(item))+" ")]}},{key:"item.owner",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getOwnerNames(item))+" ")]}},{key:"item.total_assessment",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.total_payed",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.balance",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","colored":"","value":value}})]}},{key:"item.renters_balance",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","colored":"","value":value}})]}},{key:"item.owners_balance",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","colored":"","value":value}})]}},{key:"item.has_periodical_assessments",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.has_multiple_owners",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}}],null,false,730086868),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false)):_c('p',[_vm._v("Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }