<template>
  <div>
    <v-data-table
      v-if="cluster"
      v-bind="dataTableAttrs"
      :headers="headers"
      :items="list"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page.sync="itemsPerPage"
      v-model="selectedItems"
      @update:items-per-page="handleItemsPerPageUpdated"
    >
      <template v-slot:top>
        <rs-table-title-bar
          title="Bağımsız Bölümler"
          icon="mdi mdi-home"
          :add-route="{
            name: 'definitions.houses.create',
            params: blockId ? { formParams: { block_id: blockId } } : {},
          }"
          @clear-filters="$refs.headSearch.reset"
          @reload="loadList"
          @click:edit="handleEditClick"
          :edit-enabled="selectedItems.length === 1"
          :search.sync="search.query"
          v-if="!hideTitleBar && $refs.headSearch"
          show-delete
          :delete-enabled="deleteEnabled"
          @click:delete="handleDeleteClick"
          delete-tooltip="Sadece hiç borçlandırma yapılmamış bağımsız bölümleri silebilirsiniz."
        >
          <!-- <template slot="buttons:append">
            <v-btn
              class="float-end ms-2"
              color="pink"
              outlined
              small
              @click="handleShareMeterActiveClick"
              :disabled="selectedItems.length === 0"
            >
              Pay Ölçer Aç
            </v-btn>

            <v-btn
              class="float-end ms-2"
              color="pink"
              outlined
              small
              @click="handleShareMeterPassiveClick"
              :disabled="selectedItems.length === 0"
            >
              Pay Ölçer Kapat
            </v-btn>
          </template> -->
        </rs-table-title-bar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.prepend="{ headers }">
        <rs-table-head-search
          :headers="headers"
          :search.sync="search"
          ref="headSearch"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.edit="{ item }">
        <router-link
          class="btn btn-icon btn-sm btn-clean"
          :to="{
            name: 'definitions.houses.edit',
            params: { id: item.id },
          }"
        >
          <i class="menu-icon mdi mdi-pencil"></i>
        </router-link>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.door_number="{ item, value }">
        <router-link
          :to="{
            name: 'definitions.houses.show',
            params: { id: item.id },
          }"
        >
          {{ value }}
        </router-link>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.renter="{ item }">
        {{ getRenterNames(item) }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.owner="{ item }">
        {{ getOwnerNames(item) }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.total_assessment="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.total_payed="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>
      <!-- eslint-disable -->
      <template v-slot:item.balance="{ value }">
        <rs-table-cell-number price colored :value="value" />
      </template>
      <template v-slot:item.renters_balance="{ value }">
        <rs-table-cell-number price colored :value="value" />
      </template>
      <template v-slot:item.owners_balance="{ value }">
        <rs-table-cell-number price colored :value="value" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.has_periodical_assessments="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.has_multiple_owners="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>
      <!-- eslint-enable -->
    </v-data-table>

    <p v-else>Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import hasDataTable from "@/view/mixins/hasDataTable";

export default {
  mixins: [hasDataTable],
  computed: {
    ...mapGetters(["cluster", "clusterId"]),
    deleteEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      for (const index in this.selectedItems) {
        if (parseFloat(this.selectedItems[index].total_assessment) !== 0) {
          return false;
        }
      }

      return true;
    },
  },
  props: {
    blockId: {
      type: Number,
      required: false,
      default: null,
    },
    blockIds: {
      type: Array,
      required: false,
      default: undefined,
    },
    hideTitleBar: {
      type: Boolean,
      required: false,
      default: true,
    },
    extraParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      options: {
        sortBy: ["door_number"],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "B.B. No",
          value: "door_number",
          searchable: "text",
        },
        {
          text: this.$t("labels.block"),
          value: "block_name",
          searchable: "multiselect",
          options: () => this.cluster.blocks,
          itemText: "name",
        },
        {
          text: "B.B. Tipi",
          value: "house_type_name",
          searchable: "multiselect",
          options: () => this.cluster.house_types,
          itemText: "name",
        },
        {
          text: "Kat",
          value: "floor",
          searchable: "text",
        },
        {
          text: "Tahakkuk Eden",
          value: "total_assessment",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ödenen Tutar",
          value: "total_payed",
          searchable: "number",
          align: "end",
        },
        {
          text: "BB Bakiye",
          value: "balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kiracı",
          value: "renter",
          searchable: "text",
        },
        {
          text: "Kiracı Bakiye",
          value: "renters_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ev Sahibi",
          value: "owner",
          searchable: "text",
        },
        {
          text: "Ev Sahibi Bakiye",
          value: "owners_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Periyodik Borçlandırma",
          value: "has_periodical_assessments",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: "Hissedarlı",
          value: "has_multiple_owners",
          searchable: "yesNo",
          align: "center",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        {},
        this.buildSearchParams(
          Object.assign({}, this.extraParams, this.search),
          this.options
        )
      );

      if (this.clusterId) {
        params.cluster_id = this.clusterId;
      }

      if (this.blockId) {
        params.block_name = [this.blockId];
      } else if (this.blockIds !== undefined) {
        params.block_name = this.blockIds;
      }

      this.$api
        .query(`clusters/${this.clusterId}/houses`, { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          this.$emit("update:list", list);

          if (response.data && response.data.meta) {
            this.$emit("update:meta", response.data.meta);
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.$emit("update:total", response.data.meta.total);
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getRenterNames(item) {
      const renterNames = [];

      for (const houseUser of item.house_users) {
        if (houseUser.house_user_type_id === 2) {
          renterNames.push(houseUser.resident.name);
        }
      }

      return renterNames.join(", ");
    },
    getOwnerNames(item) {
      const renterNames = [];

      for (const houseUser of item.house_users) {
        if (houseUser.house_user_type_id === 1) {
          renterNames.push(houseUser.resident.name);
        }
      }

      return renterNames.join(", ");
    },
    handleShareMeterActiveClick() {
      if (this.selectedItems.length === 0) return;

      if (
        !window.confirm(
          `${this.selectedItems.length} bağımsız bölüm için pay ölçeri aktif etmek istediğinizden emin misiniz?`
        )
      ) {
        return;
      }

      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
        this.loadList();
      }, 1000);
    },
    handleShareMeterPassiveClick() {
      if (this.selectedItems.length === 0) return;

      if (
        !window.confirm(
          `${this.selectedItems.length} bağımsız bölüm için pay ölçeri kapatmak istediğinizden emin misiniz?`
        )
      ) {
        return;
      }

      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
        this.loadList();
      }, 1000);
    },
    handleEditClick() {
      if (this.selectedItems.length) {
        this.$router.push({
          name: "definitions.houses.edit",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }, 250),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
    blockId() {
      this.loadList();
    },
    blockIds: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    extraParams: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
